import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import ReactPlayer from 'react-player'
import { motion, useViewportScroll, transform } from 'framer-motion'

import { Layout, PageHero, PageSection, AnimatedText, FeaturedEngagements } from '../components'

import { container, padding, colours, type, bgImage, pagePaddingTop, wrapper } from '../styles/global'
import { parseACF } from '../utils'
import { useScrollAnim } from '../utils/anim'
import { media } from '../styles/utils'

const Insights = (props) => {
    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages', true)

    const renderIntro = () => {
        if (!data.intro_heading || !data.intro_text) return

        const section = {
			title: data.intro_heading,
			items: [
				{
					text: data.intro_text,
					image_or_animation: 'image',
					type_size: 'medium'
				}
			]
		}	

		return (
			<PageSection 
				{...section}
			/>
		)
    }

	const renderSections = () => {
		if (!data.sections) return;

		const items = data.sections.map((item, i) => {
			if (!item.display) return;

			return (
				<PageSection 
					key={i}
					{...item}
				/>
			)
		})

		return items
	}

	return (
		<Layout
            meta={data.seo}
        >
			<Wrapper>
				<PageHero
                    data={{
						...data,
						logo: true,
                        title: '/Insights'
                    }}
                />

                <Container>
                    {renderIntro()}
                    {renderSections()}
                </Container>

				{data.featured_engagments && (
					<FeaturedEngagements 
						logosOnly={true}
						heading={data?.featured_engagments_heading}
						slides={data.featured_engagments} 
					/>
				)}
			</Wrapper>
		</Layout>	
	)
}


// Shared

const Heading = styled(motion.h2)``
const Subheading = styled.h3``
const Text = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled(motion.div)`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
`

const Video = styled(ReactPlayer)``

// Layout

const Wrapper = styled.div`
	${wrapper}

	${media.phone`
		.page-section-item {
			margin-bottom: 80px;

			.title {
				font-size: 36px;
			}
		}
	`}
`

const Container = styled.div`
	${container}
	${padding}
	${pagePaddingTop}

	display: flex;
	flex-direction: column;
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "insights" } }) {
            nodes {
                title
                slug
                acf_json
            }
        }
    }
`

export default Insights